import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import { SizeMe } from 'react-sizeme';

function Lebenslauf() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <SizeMe
            monitorHeight
            refreshRate={128}
            refreshMode={"debounce"}>
            {({ size }) => (
                <Container>
                    <h1 className='text caption'>Lebenslauf</h1>
                    <Document file='/assets/docs/CV.pdf' onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={1} width={size.width ? size.width : 1} />
                        <Page pageNumber={2} width={size.width ? size.width : 1} />
                    </Document>
                </Container>
            )}
        </SizeMe>
    )
}
export default Lebenslauf
